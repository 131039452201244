/* eslint-disable camelcase */
import { gql } from '@apollo/client';
import i18next from 'i18next';

import {
  GET_USER,
  NETWORK_STATUS,
  GET_NOTIFICATION,
  IMPERSONATE_ACCOUNT,
  THEME_TYPE,
} from 'graphql/query/user';
import { LOGIN_USER_V2, CHECK_USER_V2 } from 'graphql/mutation/user';
import { NOTIFICATION_STATUS } from 'helpers/constants';
import { activeAccountIdVar } from './apolloReactiveVariables';

export const typeDefs = gql`
  extend type Query {
    user: User!
    networkStatus: NetworkStatus
    notification: Notification
    theme: Theme
  }
  type Impersonate {
    impersonateId: String
    name: String
  }
  type Theme {
    type: String
  }
  type User {
    userData: UserData
    accessToken: String
    refreshToken: String
    idToken: String
    error: String
    isRememberChecked: Boolean
  }
  type UserData {
    name: String
    email: String
    userId: String
    picture: String
    isAdmin: Boolean
  }
  type NetworkStatus {
    isOnline: Boolean
  }
  type Notification {
    timeout: Int
    message: String
    type: NotificationType
    isOpen: Boolean
    isManual: Boolean
  }
  extend type Mutation {
    login(email: String, password: String, isRememberChecked: Boolean): Boolean!
    checkUser: User!
    isOnline(isOnline: Boolean): Boolean!
    setNotification(
      timeout: Int
      message: String
      type: NotificationType
      isOpen: Boolean
      isManual: Boolean
    ): Boolean!
    changeImpersonateId(impersonateId: String, name: String): Boolean!
    changeTheme(type: String): Boolean!
  }
`;

export const initialUserData = {
  user: {
    __typename: 'User',
    userData: {
      __typename: 'UserData',
      name: null,
      email: null,
      userId: null,
      picture: null,
      isAdmin: false,
      accountId: null,
    },
    accessToken: null,
    refreshToken: null,
    idToken: null,
    error: null,
    isRememberChecked: true,
  },
};

const setActiveAccountId = (cache, accountId) => {
  const impersonateResponse = cache.readQuery({ query: IMPERSONATE_ACCOUNT });
  const impersonateId = impersonateResponse?.impersonate.impersonateId;

  activeAccountIdVar(impersonateId || accountId);
};

export const resolvers = {
  Query: {},

  Mutation: {
    login: async (
      _,
      { email, password, isRememberChecked },
      { client, cache }
    ) => {
      let userResponse;
      try {
        const response = await client.mutate({
          mutation: LOGIN_USER_V2,
          variables: { email, password, isRememberChecked },
        });

        if (response?.data?.loginViaEmail?.accessToken) {
          userResponse = response?.data?.loginViaEmail;
        } else {
          throw new Error(
            response?.data?.loginViaEmail?.error ||
              response?.errors?.[0]?.message ||
              'Failed to login'
          );
        }
      } catch (error) {
        let message = i18next.t('errors.LOGIN_CANNOT_AUTH');

        if (error?.graphQLErrors?.[0]?.extensions?.translation) {
          message = i18next.t(
            `errors.${error?.graphQLErrors[0]?.extensions?.translation}`,
            error?.graphQLErrors[0]?.extensions?.translationVariables || {}
          );
        }

        cache.writeQuery({
          query: GET_NOTIFICATION,
          data: {
            notification: {
              timeout: 4000,
              message,
              type: NOTIFICATION_STATUS.ALERT,
              isOpen: true,
              isManual: false,
              __typename: 'Notification',
            },
          },
        });
        return {
          email,
          isRememberChecked,
          error: error?.response?.data,
        };
      }
      const accountId = userResponse?.userData.accountId;
      const userFullData = {
        user: {
          __typename: 'User',
          accessToken: userResponse?.accessToken || null,
          refreshToken: isRememberChecked ? userResponse?.refreshToken : null,
          idToken: userResponse?.accessToken || null,
          error: null,
          isRememberChecked,
          userData: {
            __typename: 'UserData',
            name: userResponse?.userData.name,
            email: userResponse?.userData.email,
            userId: userResponse?.userData.userId,
            picture: userResponse?.userData.picture || '',
            isAdmin: userResponse?.userData.isAdmin || false,
            accountId,
          },
        },
      };
      setActiveAccountId(cache, accountId);

      if (isRememberChecked) {
        cache.writeQuery({ query: GET_USER, data: userFullData });
      } else {
        await sessionStorage.setItem('user', JSON.stringify(userFullData));
        cache.writeQuery({
          query: GET_USER,
          data: {
            ...initialUserData,
            user: {
              ...initialUserData.user,
              isRememberChecked,
            },
          },
        });
      }
      return userFullData;
    },
    // eslint-disable-next-line consistent-return
    checkUser: async (_, args, { client, cache }) => {
      const data = cache.readQuery({ query: GET_USER });
      const sessionStorageData = JSON.parse(sessionStorage.getItem('user'));
      const {
        networkStatus: { isOnline },
      } = cache.readQuery({ query: NETWORK_STATUS });

      let fullUserData = { ...initialUserData };

      if (isOnline === false) {
        return data;
      }
      if (sessionStorageData || data) {
        const user = sessionStorageData?.user || data.user;
        if (user && user.accessToken) {
          let refreshTokenResponse;
          let userDataResponse;
          try {
            const response = await client.mutate({
              mutation: CHECK_USER_V2,
              context: {
                headers: {
                  authorization: user.accessToken,
                },
              },
            });

            userDataResponse = response?.data?.checkUser;

            if (!userDataResponse) {
              throw new Error('Auth token invalid');
            }

            const accountId = userDataResponse?.userData.accountId;
            fullUserData = {
              user: {
                __typename: 'User',
                accessToken: userDataResponse?.accessToken || null,
                refreshToken:
                  userDataResponse?.refreshToken || user.refreshToken || null,
                idToken: userDataResponse?.accessToken || null,
                error: null,
                isRememberChecked: !!sessionStorageData,
                userData: {
                  __typename: 'UserData',
                  name: userDataResponse?.userData?.name,
                  email: userDataResponse?.userData?.email,
                  userId: userDataResponse?.userData?.userId,
                  picture: userDataResponse?.userData?.picture || '',
                  isAdmin: userDataResponse?.userData?.isAdmin || false,
                  accountId,
                },
              },
            };
            setActiveAccountId(cache, accountId);

            if (sessionStorageData) {
              sessionStorage.setItem('user', JSON.stringify(fullUserData));
            } else {
              cache.writeQuery({ query: GET_USER, data: fullUserData });
            }
            return fullUserData;
          } catch (error) {
            console.error(error);
            // NOTE: error with accessToken try to refresh
            try {
              if (!user.refreshToken) {
                throw new Error('No refresh token');
              }

              refreshTokenResponse = await client.mutate({
                mutation: CHECK_USER_V2,
                context: {
                  headers: {
                    authorization: user.accessToken,
                    'refresh-token': user.refreshToken,
                  },
                },
              });
              userDataResponse = refreshTokenResponse?.data?.checkUser;

              if (!userDataResponse) {
                throw new Error('Refresh token invalid');
              }

              const accountId = userDataResponse?.userData.accountId;
              fullUserData = {
                user: {
                  __typename: 'User',
                  accessToken: userDataResponse?.accessToken || null,
                  refreshToken: userDataResponse?.refreshToken || null,
                  idToken: userDataResponse?.accessToken || null,
                  error: null,
                  isRememberChecked: !!sessionStorageData,
                  userData: {
                    __typename: 'UserData',
                    name: userDataResponse?.userData?.name,
                    email: userDataResponse?.userData?.email,
                    userId: userDataResponse?.userData?.userId,
                    picture: userDataResponse?.userData?.picture || '',
                    isAdmin: userDataResponse?.userData?.isAdmin || false,
                    accountId,
                  },
                },
              };
              setActiveAccountId(cache, accountId);
            } catch (refreshTokenError) {
              console.error(error);

              cache.writeQuery({
                query: GET_USER,
                data: {
                  user: {
                    ...initialUserData.user,
                    error: i18next.t('common.Please login again'),
                  },
                },
              });
              return initialUserData;
            }
          }

          if (sessionStorageData) {
            sessionStorage.setItem('user', JSON.stringify(fullUserData));
          } else {
            cache.writeQuery({ query: GET_USER, data: fullUserData });
          }
          return fullUserData;
        } else {
          cache.writeQuery({
            query: GET_USER,
            data: {
              ...initialUserData,
            },
          });
          return initialUserData;
        }
      }
    },
    isOnline: async (_, { isOnline }, { cache }) => {
      const data = {
        networkStatus: {
          __typename: 'NetworkStatus',
          isOnline,
        },
      };
      cache.writeQuery({ query: NETWORK_STATUS, data });
      return data;
    },
    setNotification: async (
      _,
      { timeout, message, type, isOpen, isManual },
      { cache }
    ) => {
      const data = {
        notification: {
          __typename: 'Notification',
          timeout,
          message,
          type,
          isOpen,
          isManual: isManual || false,
        },
      };
      cache.writeQuery({ query: GET_NOTIFICATION, data });
    },
    changeImpersonateId: (_, { impersonateId, name }, { cache }) => {
      cache.writeQuery({
        query: IMPERSONATE_ACCOUNT,
        data: {
          impersonate: {
            impersonateId,
            name,
            __typename: 'Impersonate',
          },
        },
      });
    },
    changeTheme: (_, { type }, { cache }) => {
      cache.writeQuery({
        query: THEME_TYPE,
        data: {
          theme: {
            type,
            __typename: 'Theme',
          },
        },
      });
    },
  },
};
