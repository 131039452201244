import {
  GET_NOTIFICATION,
  GET_USER,
  NETWORK_STATUS,
  IMPERSONATE_ACCOUNT,
  THEME_TYPE,
} from 'graphql/query/user';

export const initData = {
  user: {
    __typename: 'User',
    userData: {
      __typename: 'UserData',
      userId: null,
      name: null,
      email: null,
      picture: null,
      isAdmin: false,
      accountId: null,
    },
    accessToken: null,
    refreshToken: null,
    idToken: null,
    error: null,
    isRememberChecked: true,
  },
  networkStatus: {
    __typename: 'NetworkStatus',
    isOnline: true,
  },
  notification: {
    __typename: 'Notification',
    timeout: 0,
    message: '',
    type: 'SUCCESS',
    isOpen: false,
    isManual: false,
  },
  impersonate: {
    __typename: 'Impersonate',
    impersonateId: null,
    name: null
  },
  theme: {
    __typename: 'Theme',
    type: 'light'
  }
};

export const initClientQueries = (cache) => {
  // NOTE: when you add new @client query please init it like below
  try {
    cache.readQuery({
      query: NETWORK_STATUS
    });
  } catch (error) {
    cache.writeQuery({
      query: NETWORK_STATUS,
      data: {
        networkStatus: {
          __typename: 'NetworkStatus',
          isOnline: true,
        },
      }
    });
  }
  try {
    cache.readQuery({
      query: GET_NOTIFICATION
    });
  } catch (error) {
    cache.writeQuery({
      query: GET_NOTIFICATION,
      data: {
        notification: {
          __typename: 'Notification',
          timeout: 0,
          message: '',
          type: 'SUCCESS',
          isOpen: false,
          isManual: false,
        },
      }
    });
  }
  try {
    const data = cache.readQuery({
      query: GET_USER
    });
    if (!data?.user?.userData?.accountId) {
      console.error('User not found in cache');
      throw new Error('User not found');
    }
  } catch (error) {
    cache.writeQuery({
      query: GET_USER,
      data: {
        user: { ...initData.user },
      }
    });
  }
  try {
    cache.readQuery({
      query: IMPERSONATE_ACCOUNT
    });
  } catch (error) {
    cache.writeQuery({
      query: IMPERSONATE_ACCOUNT,
      data: {
        impersonate: {
          __typename: 'Impersonate',
          impersonateId: null,
          name: null
        },
      }
    });
  }
  try {
    cache.readQuery({
      query: THEME_TYPE
    });
  } catch (error) {
    cache.writeQuery({
      query: THEME_TYPE,
      data: {
        theme: {
          __typename: 'Theme',
          type: 'light',
        },
      }
    });
  }
};
