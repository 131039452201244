import { useQuery } from '@apollo/client';
import { activeAccountIdVar } from 'graphql/apolloReactiveVariables';
import { GET_USER } from 'graphql/query/user';
import mixpanel from 'mixpanel-browser';

function useMixPanel() {
  const isProduction = process.env.REACT_APP_ENV_NAME === 'prod';
  // NOTE: it is call this func every time but it return false after first init, we need this to expect error https://github.com/mixpanel/mixpanel-js/issues/82
  mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);

  const { data: { user } = {} } = useQuery(GET_USER);

  const isChefslistAccount = !!user?.userData?.email?.includes('@chefslist.de');

  const mixPanel = {
    identify: (id) => {
      if (isProduction && !isChefslistAccount) mixpanel.identify(id);
    },
    track: (name, props) => {
      if (isProduction && !isChefslistAccount) {
        mixpanel.track(`Fresh-${name}`, {
          ...props,
          appScope: 'FRESH',
          vendorId: activeAccountIdVar()
        });
      }
    },
    people: {
      set: (props) => {
        if (isProduction && !isChefslistAccount) mixpanel.people.set(props);
      },
    },
  };
  return mixPanel;
}

export default useMixPanel;
