/* eslint-disable max-len */
import { ApolloLink, Observable, ApolloClient } from '@apollo/client';
import { persistCache } from 'apollo-cache-persist';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';

import {
  GET_USER,
  GET_NOTIFICATION,
  INIT_CACHE,
  IMPERSONATE_ACCOUNT,
} from 'graphql/query/user';
import { resolvers, typeDefs, initialUserData } from 'graphql/resolvers';
import { initData, initClientQueries } from 'graphql/initCache';

import { NOTIFICATION_STATUS } from 'helpers/constants';
import generateRandomId from 'helpers/generateRandomId';

const apolloClientConfig = async (cache, setClient) => {
  cache.writeQuery({
    query: INIT_CACHE,
    data: {
      ...initData
    }
  });

  await persistCache({
    cache,
    storage: window.localStorage,
  });

  // NOTE: dont forget when you add new @client query init it
  initClientQueries(cache);

  const errorHandler = ({ graphQLErrors, networkError, response }) => {
    const lang = localStorage.getItem('i18nextLng') || 'de';

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        if (message === 'UNAUTHENTICATED') {
          cache.writeQuery({ query: GET_USER, data: initialUserData });
          response.errors = undefined;
          return;
        }
        cache.writeQuery({
          query: GET_NOTIFICATION,
          data: {
            notification: {
              timeout: 4000,
              message,
              type: NOTIFICATION_STATUS.ALERT,
              isOpen: true,
              isManual: false,
              __typename: 'Notification',
            }
          }
        });
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      });
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
      cache.writeQuery({
        query: GET_NOTIFICATION,
        data: {
          notification: {
            timeout: 4000,
            message: lang === 'en' ?
              'Could not connect the server. Please check your internet connection or contact our support'
              : 'Verbindung fehlgeschlagen. Bitte überprüfe deine Internetverbindung oder kontaktieren Sie unseren Support',
            type: NOTIFICATION_STATUS.ALERT,
            isOpen: true,
            isManual: false,
            __typename: 'Notification',
          }
        }
      });
    }
  };

  const request = async operation => {
    const { user } = cache.readQuery({ query: GET_USER });
    const sessionUser = JSON.parse(sessionStorage.getItem('user'));
    const { impersonate: { impersonateId } } = cache.readQuery({ query: IMPERSONATE_ACCOUNT });
    const token = user.idToken || sessionUser?.user?.idToken;
    const accountId = user.userData.accountId || sessionUser?.user?.userData?.accountId;

    const operationHeaders = operation.getContext().headers || {};

    let deviceId = localStorage.getItem('deviceId');

    if (!deviceId) {
      deviceId = generateRandomId();
      localStorage.setItem('deviceId', deviceId);
    }

    operation.setContext({
      headers: {
        authorization: token || '',
        deviceId,
        accountId: impersonateId || accountId || '',
        'app-type': 'supplier',
        ...operationHeaders,
      },
    });
  };

  const requestLink = new ApolloLink((operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));
      return () => {
        if (handle) handle.unsubscribe();
      };
    })
  );

  let link = 'http://localhost:4000';
  if (process?.env?.REACT_APP_ENV_NAME === 'prod') {
    link = process?.env.REACT_APP_STAGING_GRAPHQL_URL;
  } else if (process?.env.REACT_APP_LOCAL === 'true') {
    link = process?.env?.REACT_APP_LOCAL_GRAPHQL_URL;
  } else {
    link = process?.env?.REACT_APP_DEV_GRAPHQL_URL;
  }

  const apolloClient = new ApolloClient({
    typeDefs,
    resolvers,
    cache,
    link: ApolloLink.from([
      onError(errorHandler),
      requestLink,
      createUploadLink({
        uri: link
      }),
    ]),

  });

  setClient(apolloClient);

  apolloClient.onClearStore(async() => {
    await cache.writeQuery({
      query: INIT_CACHE,
      data: { ...initData },
    });
    cache.gc();
  });
};

export default apolloClientConfig;
